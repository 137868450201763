/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as appActions from "../../../core/app.store";
import * as documentAction from "../../../redux/store/document/document.store";
import "./document-settings.scss";

//--- Material Control
import {
  makeStyles,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  Button,
} from "@material-ui/core";
import { TreeItem } from "@material-ui/lab";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";

//--- Drag

import {
  Transition,
} from "../../../utils/configuration";
import FileManagement from "../../../components/document-management/file_management"

// import FileViewer from "react-file-viewer";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent",
    },
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 10,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    item,
    setDocument,
    GetAllDocumentByPlanning,
    ...other
  } = props;

  return (
    <TreeItem
      onClick={() => {
        if (item.isLoaded) return;
        if (item.typeName === DOCUMENT_TYPE.FOLDER) {
          GetAllDocumentByPlanning(item.id);
        } else {
          setDocument(item);
        }
      }}
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography className={classes.labelText}>{labelText}</Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  appBar: {
    position: "relative",
    backgroundColor: "#01579B",
  },
  title: {
    marginLeft: theme.spacing(0),
    flex: 1,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

// function IconFolder() {
//   return (
//     <img
//       src={require("../../../assets/icon/folder.svg")}
//       alt="Folder"
//       style={{ width: "16px", height: "16px", marginTop: "-2px" }}
//       className="mr-2"
//     />
//   );
// }

// function IconPDF() {
//   return (
//     <img
//       src={require("../../../assets/icon/pdf.svg")}
//       alt="Folder"
//       style={{ width: "16px", height: "16px", marginTop: "-2px" }}
//       className="mr-2"
//     />
//   );
// }

// function IconDOCX() {
//   return (
//     <img
//       src={require("../../../assets/icon/microsoft-word.svg")}
//       alt="Folder"
//       style={{ width: "16px", height: "16px", marginTop: "-2px" }}
//       className="mr-2"
//     />
//   );
// }

// function IconXLSX() {
//   return (
//     <img
//       src={require("../../../assets/icon/excel.svg")}
//       alt="Folder"
//       style={{ width: "16px", height: "16px", marginTop: "-2px" }}
//       className="mr-2"
//     />
//   );
// }

// function IconImages() {
//   return (
//     <img
//       src={require("../../../assets/icon/picture.svg")}
//       alt="Folder"
//       style={{ width: "16px", height: "16px", marginTop: "-2px" }}
//       className="mr-2"
//     />
//   );
// }

// function IconOtherFile() {
//   return (
//     <img
//       src={require("../../../assets/icon/paper.svg")}
//       alt="Folder"
//       style={{ width: "16px", height: "16px", marginTop: "-2px" }}
//       className="mr-2"
//     />
//   );
// }

const DOCUMENT_TYPE = {
  FOLDER: "Folder",
  FILE: "File",
};

function DocumentSettings(props) {
  const {
    showLoading,
    isShowDialog,
    onCloseDialog,
    planningId,
    planningName,
  } = props;

  const [folderList, setFolderList] = useState([]);
  const [files, setFiles] = useState([]);
  const [document, setDocument] = useState(null);

  // useEffect(() => {
  //   GetAllDocumentByPlanning();
  // }, []);

  const GetAllDocumentByPlanning = (parentId = 0) => {
    showLoading(true);
    documentAction
      .GetAllDocumentByPlanning(planningId, parentId)
      .then((res) => {
        if (parentId === 0) {
          setFolderList(
            res && res.content
              ? res.content.map((item) => {
                return {
                  ...item,
                  isLoaded: false,
                  itemList: [],
                };
              })
              : []
          );
        } else {
          folderList &&
            folderList.length > 0 &&
            loopGetDataByParentId(
              folderList,
              res && res.content ? res.content : []
            );
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const classes = useStyles();

  function loopGetDataByParentId(folderList, data) {
    let folderListTemp = [...folderList];
    for (let index = 0; index < folderListTemp.length; index++) {
      data.filter((item) => {
        if (folderListTemp[index].id === item.parentId) {
          if (folderListTemp[index].itemList.length < data.length) {
            folderListTemp[index].isLoaded = true;
            folderListTemp[index].itemList.push({
              ...item,
              itemList: [],
            });
          }
        } else {
          folderListTemp[index].type === 0 &&
            loopGetDataByParentId(folderListTemp[index].itemList, data);
        }
      });
    }
    setFolderList(folderListTemp);
    return folderListTemp;
  }

  return (
    <Dialog
      fullScreen
      open={isShowDialog}
      onClose={onCloseDialog}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Thiết lập hồ sơ ({planningName})
          </Typography>
          <Button color="inherit" onClick={onCloseDialog}>
            <CloseIcon />
          </Button>
        </Toolbar>
      </AppBar>	


      <FileManagement
        planningId={planningId}
        showLoading={showLoading}
        files={files}
        setFiles={setFiles}
        acceptedFiles={['.doc', '.docx', '.txt', '.pdf']}
      />
    </Dialog>
  );
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSettings);
